import type { RendererElement } from 'vue';

import type { SearchProject, SearchProjectResponse } from '@/shared/types';
import type { SelectOption } from '@/packages/app-ui/src/options-list/types';
import { $t } from '@/localization';
import { arrayOfStringsToMap } from '@/shared/utils/array-to-map';

import { ProjectSearchMinQueryLength } from './constants';
import type { UseProjectSearchOptions } from './types';

export function useProjectSearch(options: UseProjectSearchOptions) {
  const { label, component, fetchLimit } = options;
  const { $api } = useNuxtApp();

  const searchModel = ref('');
  const isLoading = ref<boolean>(false);

  const sourceOptions = useState('sourceOptions', () => shallowRef<SearchProject[]>([]));
  const filteredSearch = shallowRef<SearchProject[]>([]);
  const initialOptionsFetchLimit = shallowRef<number>(fetchLimit || 3);
  const projectOptions = shallowRef<SelectOption[]>([]);
  const customOptions = shallowRef<RendererElement[]>([]);
  const projectOptionsLeft = shallowRef<number>(0);
  const projectsListPage = shallowRef(1);

  function filterSearchProjectData(data: SearchProject[], compareData: SearchProject[]): SearchProject[] {
    const idsMap = arrayOfStringsToMap(compareData.map(item => item.id.toString()));
    return data.filter(project => project.id !== +idsMap[project.id.toString()]);
  }

  async function fetchSearchProject(title: string): Promise<SearchProject[]> {
    return await $api<SearchProject[]>('/projects/search', { params: { title } });
  }

  async function fetchSearchId(ids: string[]) {
    if (!ids.length) {
      return;
    }
    const { data } = await useApi<SearchProject[]>('/projects/search-id', {
      params: { ids: ids.toString() },
      watch: false,
    });
    const filteredData = filterSearchProjectData(data.value || [], sourceOptions.value);
    sourceOptions.value = [...filteredData, ...sourceOptions.value];
  }

  const onSearchProjects = useDebounceFn(async (value: string, ids?: { [key: string]: string }) => {
    isLoading.value = true;

    searchModel.value = value;
    projectsListPage.value = 1;

    const selected: SearchProject[] = [];
    if (ids) {
      const filtered = sourceOptions.value.filter(source => source.id === +ids[source.id.toString()]);
      selected.push(...filtered);
    }

    if (value.length > ProjectSearchMinQueryLength) {
      const data = await fetchSearchProject(value);
      if (!data) {
        return;
      }
      const filteredData = filterSearchProjectData(data, selected);
      sourceOptions.value = [...filteredData, ...selected];

      updateOptions(sourceOptions.value);
    } else {
      sourceOptions.value = [...selected];
      await fetchInitialOptions(projectsListPage.value);
    }

    isLoading.value = false;
  }, 300);

  function prepareOptions(data: SearchProject[]): SelectOption {
    const children = data.map(project => (
      { label: project.title, name: options.isMultiSearch ? project.id : project.slug }
    ));
    return {
      label: label ? $t(label) : undefined,
      type: 'group',
      children,
    };
  }

  function updateOptions(data: SearchProject[]): void {
    const option = prepareOptions(data);
    projectOptions.value = data.length ? [option] : [];

    if (data.length) {
      customOptions.value = data.map(option => prepareComponent(option));
    } else {
      customOptions.value = [];
    }
  }

  function prepareComponent({ title, main_image, availabilities_count }: SearchProject) {
    return h(
      component,
      {
        title,
        main_image,
        availabilities_count,
        highLightedValue: searchModel.value,
      },
    );
  }

  async function fetchInitialOptions(page?: number): Promise<void> {
    const pageNumber = page || projectsListPage.value;
    await useAsyncApi(`projects-recommended-${initialOptionsFetchLimit.value}-${pageNumber}`, async () => {
      const projects = await $api<SearchProjectResponse>('/projects/recommended', {
        params: {
          pageSize: initialOptionsFetchLimit.value,
          pageNumber,
        },
      });
      if (!projects) {
        return;
      }

      const filteredData = filterSearchProjectData(projects.data, sourceOptions.value);
      projectOptionsLeft.value = projects.left;
      sourceOptions.value = [...sourceOptions.value, ...filteredData];

      updateOptions(sourceOptions.value);
    });
  }

  async function loadMoreProjectOptions(hasSearchQuery: boolean = false) {
    const isNoMoreLeft = projectOptionsLeft.value === 0 || hasSearchQuery;
    if (isNoMoreLeft) {
      return;
    }

    projectsListPage.value += 1;
    await fetchInitialOptions(projectsListPage.value);
  }

  onBeforeUnmount(() => {
    sourceOptions.value = [];
  });

  return {
    projectOptions,
    filteredSearch,
    customOptions,
    sourceOptions,
    isLoading,
    fetchSearchId,
    onSearchProjects,
    fetchInitialOptions,
    loadMoreProjectOptions,
  };
}
